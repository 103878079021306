var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    [
      _c("h1", { staticClass: "mb-3 text-3xl" }, [
        _vm._v(_vm._s(_vm.plan.name))
      ]),
      _vm._v(" "),
      _c("field-list", {
        attrs: { fields: _vm.fields },
        scopedSlots: _vm._u([
          {
            key: "extra-fields",
            fn: function() {
              return [
                _vm.plan.document
                  ? _c("field-item", {
                      attrs: { name: "Document", value: _vm.plan.document },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "value",
                            fn: function() {
                              return [
                                _c(
                                  "a",
                                  {
                                    staticClass: "border-b-2 border-current",
                                    attrs: { href: _vm.plan.document }
                                  },
                                  [_vm._v(_vm._s(_vm.plan.document))]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3702781868
                      )
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }