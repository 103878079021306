var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    {
      staticClass:
        "flex flex-col-reverse sm:flex-row md:flex-col-reverse lg:flex-row"
    },
    [
      _c("h1", { staticClass: "sr-only" }, [_vm._v("Area plans")]),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass:
            "w-full sm:w-1/3 md:w-full lg:w-1/3 xl:w-1/4 h-full sm:h-screen md:h-full lg:h-(screen-16) overflow-y-auto border-t sm:border-t-0 md:border-t lg:border-t-0 sm:border-r md:border-r-0 lg:border-r border-black"
        },
        [
          !_vm.$route.params.id
            ? _c("section", { staticClass: "m-2" }, [
                _c(
                  "header",
                  [
                    _c("address-suggest", {
                      on: { "candidate-select": _vm.goToAddress }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "section",
                  {
                    staticClass:
                      "my-2 border border-gray-500 rounded shadow bg-gray-100 text-gray-900",
                    attrs: { id: "filters" }
                  },
                  [
                    _c("header", { class: { "border-b": _vm.showFilters } }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "p-2 w-full flex items-center justify-between",
                          on: {
                            click: function($event) {
                              _vm.showFilters = !_vm.showFilters
                            }
                          }
                        },
                        [
                          _c("h2", { staticClass: "flex items-center" }, [
                            _c("i", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.feather.icons["settings"].toSvg({
                                    class: "w-5 h-5"
                                  })
                                )
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "px-2" }, [
                              _vm._v("Settings")
                            ])
                          ]),
                          _vm._v(" "),
                          !_vm.showFilters
                            ? _c("i", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.feather.icons["chevron-down"].toSvg({
                                      class: "w-5 h-5"
                                    })
                                  )
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showFilters
                            ? _c("i", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.feather.icons["chevron-up"].toSvg({
                                      class: "w-5 h-5"
                                    })
                                  )
                                }
                              })
                            : _vm._e()
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "main",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showFilters,
                            expression: "showFilters"
                          }
                        ],
                        staticClass: "p-2",
                        attrs: { "aria-expanded": "" + _vm.showFilters }
                      },
                      [
                        _c(
                          "form",
                          {
                            staticClass: "grid grid-cols-1 gap-1",
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [
                            _c("div", [
                              _c(
                                "label",
                                { staticClass: "flex items-center" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.filterExtent,
                                        expression: "filterExtent"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "filterExtent"
                                    },
                                    domProps: {
                                      checked: Array.isArray(_vm.filterExtent)
                                        ? _vm._i(_vm.filterExtent, null) > -1
                                        : _vm.filterExtent
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.filterExtent,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.filterExtent = $$a.concat([
                                                $$v
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.filterExtent = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.filterExtent = $$c
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "px-2" }, [
                                    _vm._v("Filter plans by map extent")
                                  ])
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "my-2" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "px-2 py-1 border border-blue-900 rounded-md bg-blue-500 text-blue-100 flex items-center",
                                  on: { click: _vm.resetExtent }
                                },
                                [
                                  _c("i", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.feather.icons["maximize-2"].toSvg({
                                          class: "w-5 h-5"
                                        })
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "pl-2" }, [
                                    _vm._v("Reset map extent")
                                  ])
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "list-none" },
                  _vm._l(_vm.filteredPlans, function(plan) {
                    return _c(
                      "li",
                      { key: plan.id },
                      [
                        _c("plan-listing", {
                          attrs: { plan: plan },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function() {
                                  return [
                                    _c("h3", { staticClass: "text-lg" }, [
                                      _vm._v(_vm._s(plan.name))
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              ])
            : _c(
                "section",
                { staticClass: "m-2" },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "border-current border-b-2 transition ease-in-out duration-150 hover:text-blue-600 focus:text-blue-600",
                          attrs: { to: "/area-plans" }
                        },
                        [_vm._v("Back to results")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.plansList && _vm.plansList.length > 1
                    ? _c("pager", {
                        staticClass: "my-3",
                        attrs: { list: _vm.plansList },
                        on: {
                          next: function($event) {
                            return _vm.handlePlanChange(_vm.selectionIndex + 1)
                          },
                          prev: function($event) {
                            return _vm.handlePlanChange(_vm.selectionIndex - 1)
                          }
                        },
                        model: {
                          value: _vm.selectionIndex,
                          callback: function($$v) {
                            _vm.selectionIndex = $$v
                          },
                          expression: "selectionIndex"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedPlan
                    ? _c(
                        "main",
                        [
                          _c("plan-full", { attrs: { plan: _vm.selectedPlan } })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "w-full h-screen-50 lg:h-(screen-16)" },
        [
          _c("app-map", {
            attrs: { layers: _vm.layers },
            on: { click: _vm.handleClick }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }